import React from 'react';
import moment from 'moment';

import { PageContainer } from '../../../components/containers/PageContainer';
import { Footer } from '../../../components/layouts/Footer';
import { HelmetContainer } from '../../../components/pageComponents/helmet';
import { Header } from '../../../components/layouts/HeaderComponent';
import { LargeHero } from '../../../components/V2/LargeHero/LargeHero';
import { BackgroundGrey, BackgroundHalfGrey, BackgroundAI, BackgroundWhite } from '../../../GlobalStyles';
import { PlanComparisonTable } from '../../../components/V2024/PlanComparisonTable/PlanComparisonTable';
import { Accordion } from '../../../components/V2/Accordion/Accordion';

const GeomagicDesignXPlans = () => {
  const Lang = 'fr';
  const data = require(`../../../../data/content/Pages/products/geomagic-designx/plans/${Lang}.json`);
  const globalCopy = require(`../../../../data/content/Global/${Lang}.json`);
  const sitemap = require(`../../../../data/sitemap-data.json`);

  const vertical = 'NewForm-GDX';

  return (
    <PageContainer lang={Lang}>
      <BackgroundGrey>
        <HelmetContainer
          currentPage={'geomagic-designx'}
          image="/assets/images/pages/geomagicDesignX/key-visual-designx.png"
          title={data.helmet.title}
          pageDescription={data.helmet.pageDescription}
          description={data.helmet.pageDescription}
        />
        <Header lang={Lang} demoVertical={vertical} demoTitle={globalCopy.Buttons.RequestFreeTrial} />

        <LargeHero
          Country={Lang}
          AddSalesTel={true}
          AddSupportBTN={false}
          BackgroundStyle={'image'}
          BackgroundColour={'off-white'}
          Logo={'geomagic-designx'}
          Copy={data.content.hero.copy}
          Background={'/assets/2024/geomagic-designx/highlight-block.webp'}
          Button={{
            theme: 'geomagic-designx',
            copy: globalCopy.Buttons.DownloadFreeTrial,
            link: sitemap['geomagic-designx-download'][Lang]
          }}
        />
      </BackgroundGrey>
      <BackgroundWhite>
        <PlanComparisonTable {...data.content.plans} sitemap={sitemap} globalCopy={globalCopy} lang={Lang} />
      </BackgroundWhite>

      <BackgroundGrey>
        <Accordion {...data.content.Accordion} />
      </BackgroundGrey>

      <Footer />
    </PageContainer>
  );
};

export default GeomagicDesignXPlans;
