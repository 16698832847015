import React from 'react';
import moment from 'moment';

import { PageContainer } from '../../components/containers/PageContainer';
import { Footer } from '../../components/layouts/Footer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { Header } from '../../components/layouts/HeaderComponent';
import { LargeHero } from '../../components/V2/LargeHero/LargeHero';
import { GDXVideo } from '../../components/V2024/GDXVideo/GDXVideo';
import { BackgroundGrey, BackgroundHalfGrey, BackgroundAI, BackgroundWhite } from '../../GlobalStyles';
import { PlansTable } from '../../components/V2024/Plan/PlansTable';
import { ComponentMap } from '../../components/V2024/ComponentMap/ComponentMap';
import { ArticleBlock } from '../../components/V2/ArticleBlock/ArticleBlock';
import { SolutionBlock } from '../../components/V2/SolutionBlock/SolutionBlock';
import { HighlightBlock } from '../../components/V2/HighlightBlock/HighlightBlock';
import { Accordion } from '../../components/V2/Accordion/Accordion';
import { SkinnyCTA } from '../../components/V2024/SkinnyCTA/SkinnyCTA';
import { Reviews } from '../../components/V2024/Reviews/Reviews';

const GeomagicDesignX = () => {
  const Lang = 'es';
  const data = require(`../../../data/content/Pages/products/geomagic-designx/v2/${Lang}.json`);
  const globalCopy = require(`../../../data/content/Global/${Lang}.json`);
  const sitemap = require(`../../../data/sitemap-data.json`);

  const vertical = 'NewForm-GDX';

  return (
    <PageContainer lang={Lang}>
      <BackgroundGrey>
        <HelmetContainer
          currentPage={'geomagic-designx'}
          image="/assets/share/designx.jpg"
          title={data.helmet.title}
          pageDescription={data.helmet.pageDescription}
          description={data.helmet.description}
        />
        <Header lang={Lang} demoVertical={vertical} demoTitle={globalCopy.Buttons.RequestFreeTrial} />

        <LargeHero
          SecondaryLogo={{
            Logo: 'https://assets.capterra.com/badge/7efb3e2ab8d3bf7cfcdf2617994471f8.svg?v=2225314&p=276905',
            Alt: ''
          }}
          Country={Lang}
          AddSalesTel={true}
          AddSupportBTN={true}
          BackgroundStyle={'image'}
          BackgroundColour={'off-white'}
          Logo={'geomagic-designx'}
          Copy={data.content.hero.copy}
          Background={'/assets/images/pages/geomagicDesignX/3DReverseEngineeringSoftware.webp'}
          Button={{
            theme: 'geomagic-designx',
            copy: globalCopy.Buttons.DownloadFreeTrial,
            link: sitemap['geomagic-designx-download'][Lang]
          }}
        />

        <GDXVideo Title={data.content.video_content.title} Copy={data.content.video_content.copy} />
      </BackgroundGrey>
      <BackgroundAI>
        <PlansTable
          Title={data.content.plans.title}
          Subtitle={data.content.plans.subtitle}
          Items={data.content.plans.plans}
        />

        <SkinnyCTA {...data.content.skinnyCTA} />
      </BackgroundAI>

      <BackgroundWhite>
        <Reviews {...data.content.reviews} />
      </BackgroundWhite>

      <BackgroundGrey>
        <ComponentMap Components={data.content.components} />
      </BackgroundGrey>

      <ArticleBlock
        id={'customer-stories'}
        Title={'Geomagic Design X customer stories'}
        Layout={'layout-1'}
        Link={{
          copy: 'All Customer Stories',
          link: '/news/?&category=Customer-Story'
        }}
        MostRecentBlogs={true}
        Topic={'geomagic-designx-customer-stories'}
      />

      <BackgroundHalfGrey>
        <HighlightBlock
          Theme={'accent-red'}
          Title={data.content.HighlightBlock.Title}
          Button={{
            copy: globalCopy.Buttons.DownloadFreeTrial,
            link: sitemap['geomagic-designx-download'][Lang]
          }}
          Image={{
            title: '',
            alt: '',
            url: '/assets/2024/geomagic-designx/highlight-block.webp'
          }}
        />
      </BackgroundHalfGrey>

      <BackgroundGrey>
        <Accordion id="faqs" Title={data.content.Accordion.title} Items={data.content.Accordion.Items} />
      </BackgroundGrey>

      <SolutionBlock
        Title={data.content.SolutionBlock.Title}
        Theme={'dark'}
        Items={[
          {
            logo: 'geomagic-controlx',
            title: data.content.SolutionBlock.Items[0],
            link: globalCopy.Navigation.ProductURLs.geoControlX
          },
          {
            logo: 'geomagic-wrap',
            title: data.content.SolutionBlock.Items[1],
            link: globalCopy.Navigation.ProductURLs.geoWrap
          },
          {
            logo: 'geomagic-for-solid',
            title: data.content.SolutionBlock.Items[2],
            link: globalCopy.Navigation.ProductURLs.GeomagicForSolidworks
          }
        ]}
      />

      <Footer />
    </PageContainer>
  );
};

export default GeomagicDesignX;
